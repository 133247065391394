<template>
  <section id="family">
    <v-container class="pa-8">
      <v-row
        class="my-5 px-5"
      >
        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined>
            <v-img
              src="/static/asian-couple-argument.jpg"
              height="250"
            />
          </v-card>
        </v-col>

        <v-col
          class="px-0"
          cols="12"
          md="8"
        >
          <v-card
            flat
            color="transparent"
            class="px-5"
          >
            <v-card-title class="big-text">Family Counseling</v-card-title>

            <v-card-text class="pr-16">
              Te dolor consul mei, etiam molestie indoctum usu ad, qui inermis eleifend an. Pro iriure alienum incorrupte ex, cu diceret moderatius mei. Te corrumpit posidonium sit, in cum nonumy utamur. Usu impedit patrioque vituperatoribus ei, cu voluptua nominati philosophia mel. Sea at legendos percipitur, nulla dicant convenire ne duo. Veniam epicuri vis cu, ea pri alii ceteros fierent.
            </v-card-text>
            <v-btn large class="px-5 ml-5">
                Book Session
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        class="my-5 px-5"
      >
      <v-col
          cols="12"
        >
        <h3 class="med-text">What Doug Does</h3>
      </v-col>
      <v-col v-for="(colitem, colindex) in dogdoescolumns" :key="colindex" class="px-5" align="center">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title v-for="(item, index) in whatdougdoesitems" :key="index" class="mb-3">
              {{item}}
               </v-list-item-title>
          </v-list-item-content>
        <v-divider v-if="colindex < 2" vertical></v-divider>
        </v-list-item>
      </v-col>
      </v-row>
      <v-row
        class="my-5 px-5"
      >
        <v-col
          class="px-0"
          cols="12"
          md="8"
        >
          <v-card
            flat
            color="transparent"
          >
            <v-card-title class="big-text">Fixing Family</v-card-title>

            <v-card-text class="pr-16">
              Te dolor consul mei, etiam molestie indoctum usu ad, qui inermis eleifend an. Pro iriure alienum incorrupte ex, cu diceret moderatius mei. Te corrumpit posidonium sit, in cum nonumy utamur. Usu impedit patrioque vituperatoribus ei, cu voluptua nominati philosophia mel. Sea at legendos percipitur, nulla dicant convenire ne duo. Veniam epicuri vis cu, ea pri alii ceteros fierent.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card outlined>
            <v-img
              src="/static/asian-couple-cooking-in-the-kitchen.jpg"
              height="250"
            />
          </v-card>
        </v-col>
      </v-row>
      
    </v-container>
    <AccentBar />
    <!-- <MidImage :bartype="'happy'"/> -->
    <ContactBar />
  </section>
</template>

<script>
  export default {
    metaInfo: {
      title: 'Services',
      meta: [
        { name: 'description', content: 'Customized vue-cli templates for Vue and Vuetify' }
      ]
    },

    components: {
      Heading: () => import('@/components/Heading'),
      Testimonial: () => import('@/components/Testimonial'),
      GetAQuote: () => import('@/components/GetAQuote'),
      ContactBar: () => import('@/components/ContactBar'),
      MidImage: () => import('@/components/MidImage'),
      AccentBar: () => import('@/components/AccentBar')
    },

    data: () => ({
      testimonial: {
        blurb: 'With Alpha Construction managing all of our customer-facing services and ASCC, helping us monitor and understand building performance, Abocado is in great shape to create and release new products.',
        person: 'Jay Oakrson',
        title: 'CEO Abocado'
      },
      whatdougdoesitems: [
      "Abuse", 
      "Addictions", 
      "Adoption Issues", 
      "Anger Management", 
      "Anxiety", 
      "ADD", 
      "ADHD", 
      "Cognitive Behavioral Therapy", 
      "Children", 
      "Depression"]
    }),
    computed:{
      dogdoescolumns(){
        console.log('dog columns', this.whatdougdoesitems.length / 3)
        return Math.floor(this.whatdougdoesitems.length / 3)
      }
    }
  }
</script>
<style scoped>
.big-text {
    font-size: 3em;
}
.midbar-text {
    font-size: 1.5em;
}
</style>
